html {
  display:block;
  position:relative;

  max-width: 420px !important;
  max-height: 930px !important;
  margin: 0 auto;
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #c770f0;
}
.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #f1f1f1;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}
.terrain {
  top: 0rem;
  margin: 0vw 80px;
  max-width: 400px !important;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
}
.terrain-background {
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #f1f1f1;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 7vw;
  position: relative;
  top: 0;
}
.loading {
  position:relative;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #f1f1f1;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 7vw;
  z-index: 999;
  top: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */
.hide-scroll::-webkit-scrollbar {
  display: none;
}
.hide-scroll {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsla(60, 4%, 11%, 0.854);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1e0;
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #1d1d1b !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(50px) !important;

}

.navbar {
  font-family: 'BebasNeuePro', sans-serif;
  text-align: left;
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  /* padding: 0.3rem 2rem !important; */
  font-size: 1rem !important;
  /* border-bottom: 1px solid #1d1d1b10; */
  margin: 0 auto;
  max-width: 420px;
  padding-left: 1em;
  /* background-color: #1b1b1d; */
}

.navbar-toggler {
  position: relative !important;
  background-color: none !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  background-color: #1d1d1b;
  display: block !important;
  height: 1px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  height: 4px !important;
  width: 17px !important;
  position: absolute !important;
  left: 28px !important;
  top: 0px !important;
  transform: rotate(-90deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 6px !important;
  top: 8px !important;
  transform: rotate(90deg) !important;
  /* visibility: hidden !important; */
  /* background-color: transparent !important; */
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  height: 4px !important;
  width: 18px !important;
  position: absolute !important;
  left: 5px !important;
  top: 10px !important;
  bottom: 5px;
  transform: rotate(-90deg) !important;
  opacity: 0.9 !important;
}

/* .navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
} */


  .navbar {
    padding: 0 1rem 0 1.5rem !important;
    font-size: 4.5rem !important;
    background-color: #1d1d1b !important;
    line-height: 80%;
  }
  .navbar-trans {
    padding: 0 1rem 0 1rem !important;
    font-size: 4.5rem !important;
    background-color: transparent !important;
    line-height: 80%;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }



.navbar-brand {
  color: rgb(250, 250, 250) ;
}
.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: #ffffff !important;
  padding-right: 1rem !important;
  padding-left: 0rem !important;
  text-align: left;
}

.nav-link {
  padding: 1.5rem 1.0rem ;
}

/* @media (max-width: 200px) {
  .quote-lan {
    margin: 16.6px 78.5px ;
  }
} */

.navbar-nav .nav-item {
  position: relative;
  /* margin-left: 20px; */
  text-align: left;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 3px;
  width: 0;
  border-radius: 16px;
  background: #f1f1f1;
  bottom: 1px;
  left: 0;
  z-index: 1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  /* padding-bottom: 30px !important; */
  /* padding-top: 50px !important; */
  background-color: #f1f1f1;
  --bs-gutter-x: '0' ;
}

.home-content {
  /* padding: 0rem 20px 0rem 20px!important; */
  color: #f1f1f1;
  text-align: left;
  /* --bs-gutter-x: '0' ; */
  padding-left:0px !important;
  padding-right:0px !important;
  /* padding-bottom: 20vw ;  */
  overflow-x: hidden ;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #cd5ff8;
}

.Typewriter__wrapper {
  font-size: 1em !important;
  color: #1b1b1d !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #b562d6 !important;
}
/*
@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}
*/
.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}
/*
@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}
*/
.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  padding-left: 20px !important;
  display: flex;
  --bs-gutter-x: '0' ;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  font-size: 1.4rem;
  font-family:'BebasNeuePro', sans-serif;
  background-color: #f1f1f1;
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
  z-index: 15;
  position: fixed !important;
  max-width: 420px;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}
/*
@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}
*/
.footer h3 {
  font-size: 1em;
  color: #1d1d1b !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  font-family: 'BebasNeuePro', sans-serif;
  color: #fff !important;
  background-color: none;
  border-color: none !important;
  height: 20px;
  width: 20px;
  padding-left: 3px;
  /* border-radius: 2px; */
  /* padding: 3px; */
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

/* @media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
} */

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

/* @media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
} */
/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

/* @media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
} */
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #3c459c5d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #934cce5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}
.fork-btn-inner::after {
  display: none !important;
}

@font-face{
  font-family: "BebasNeuePro";
  src: local("BebasNeuePro"),
  url("./components/fonts/BebasNeuePro-SemiExpRegular.ttf") format("opentype");
  font-weight: normal;
}
@font-face{
  font-family: "BebasNeueProThin";
  src: local("BebasNeueProThin"),
  url("./components/fonts/BebasNeuePro-SemiExpLight.ttf") format("opentype");
  font-weight: normal;
}
@font-face{
  font-family: "SourceHanSerif-Heavy";
  src: local("SourceHanSerif-Heavy"),
  url("./components/fonts/SourceHanSerif-Heavy.ttc") format("opentype");
  font-weight: normal;
}
@font-face{
  font-family: "SourceHanSerif-Medium";
  src: local("SourceHanSerif-Medium"),
  url("./components/fonts/SourceHanSerif-Medium.ttc") format("opentype");
  font-weight: normal;
}
.navbar-header {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 1.4rem !important;
  color: #f1f1f1 !important;
}
.navbar-header-grey {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 1.4rem !important;
  color: #1d1d1b !important;
}
@media (min-width: 768px) 
{.navbar-expand-md{
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}
}
.dashboard-header {
  font-family: 'BebasNeuePro',monospace, sans-serif;
  font-size: 32px !important;
  color: #1d1d1b;
  text-align: left;
  display: inline-block;
  line-height: 100%;
  padding-top: 0rem;
}
.dashboard-img-header-en {
  font-family: 'BebasNeuePro',monospace, sans-serif;
  font-size: 90px ;
  font-weight: bold;
  color: #1d1d1b;
  text-align: left;
  /* display: inline-block; */
  padding-top: 0rem;
  top: 90px;
  left: 12%;
  line-height: 80%;
  z-index: 1 !important;
  position: absolute !important;
  text-shadow: 1px 1px 3px #1d1d1b7d;
}
.dashboard-img-header {
  font-family: 'BebasNeuePro',monospace, sans-serif;
  font-size: 60px ;
  font-weight: bold;
  color: #1d1d1b;
  text-align: left;
  /* display: inline-block; */
  padding-top: 0rem;
  top: 90px;
  left: 12%;
  line-height: 80%;
  z-index: 1 !important;
  position: absolute !important;
  text-shadow: 1px 1px 3px #1d1d1b7d;
}
.schedule-header {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 32px !important;
  align-items: flex-start;
  /* letter-spacing:1px; */
  /* font-weight: bold; */
  /* padding-left: 20px !important; */
  color: #1d1d1b;
  text-align: left;
  display: inline-block;
  line-height: 90%;
  padding-top: 0rem;
}
.dashboard-grey {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 1em !important;
  /* font-weight: 1000; */
  text-align: left;
  color: #b0b1ac;
  display: inline-block;
  /* line-height: 80%; */
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
}
.home-about-body {
  font-family: 'BebasNeuePro', sans-serif;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 14px !important;
  font-weight: 400;
  text-align: left;
  color: #1d1d1b;
  line-height: 120%;
}
.left-border {
  border-left: 2px solid #1d1d1b; /* Adjust the border color and width as needed */
  padding-left: 30px; /* Add some padding to the left side */
  min-height: 262px;
  max-width: 126px;
  margin: 0 auto;
}
.left-border-sch {
  border-left: 2px solid #1d1d1b; /* Adjust the border color and width as needed */
  padding-left: 30px; /* Add some padding to the left side */
  /* min-height: 262px; */
}
.bottom-border {
  border-bottom: 2px solid #1d1d1b; /* Adjust the border color and width as needed */
}
.dashboard-arrow-link-en,
.dashboard-arrow-link {
  z-index: 99;
  vertical-align: bottom;
  position: absolute !important;
  display: flex !important;
  pointer-events: auto;
  /* margin-top: 41vh; */
  bottom: 0;
  padding: 1rem !important;
}
@media (min-height: 730px) {
  .dashboard-arrow-link {
    margin-top: 330px ;
  }
  .dashboard-arrow-link-en {
    margin-top: 320px ;
  }
}
@media (min-width: 768px) {

  .navbar-toggler  {
    display: block !important;
  }
  
  .collapse:not(.show) {
    display: none !important;
  }

  .navbar-expand-md .navbar-nav :not(.show) {
    display: flex !important;
    flex-direction: column !important;

  }
  .navbar-trans .container .navbar-collapse{
    flex-grow: 0 !important;
    opacity: 0%;

  }
  .navbar-expand-md .navbar-nav{
    flex-direction: column !important;
    margin-left: 0px !important;
  }


}

.current {
  font-family: 'BebasNeuePro', sans-serif;
  transition: color 0.3s ease; /* Add a smooth color transition on hover */
  font-size: 15px;
}
.schedule-arrow-link {
  color: #1d1d1b !important; /* Set the desired color for the icon */
  text-decoration: none; /* Remove the default underline */
  transition: color 0.3s ease; /* Add a smooth color transition on hover */
  padding-left: 0;
  position: relative !important;
  display: inline-block !important;
  width: 60px !important;
  height: 60px !important;
  z-index: 3;
  /* display: flex; */
}

.schedule-arrow-link:hover {
  color: #d4d4d4!important; /* Change the color on hover */
  transition: color 0.3s ease; /* Add a smooth color transition on hover */
  transform-origin: center center!important;
  transform: scale(1.3);
  transition: 0.5s;
}
/* .dashboard-arrow-link:hover {
  color: #565650!important;
  transition: color 0.3s ease; 
  transform-origin: center center!important;
  transition: 0.5s;
} */
.flex-content {
  display: inline-block;
}
.show-schedule-link {
  text-align: right;
  display: inline-block;
  color: #1d1d1b !important; /* Set the desired color for the icon */
  text-decoration: none; /* Remove the default underline */
  transition: color 0.3s ease; /* Add a smooth color transition on hover */
  border: none; /* Remove the button border */
  background-color: none; /* Remove the button background */
}

.show-schedule-link:hover {
  color: #d4d4d4!important; /* Change the color on hover */
  transform-origin: center center!important;
  transform: scale(1.1);
  transition: 1s;
}
.about-header {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 100px;
  /* font-weight: bold; */
  padding-left: 0px !important;
  padding-bottom: 20px !important;
  text-align: left;
  color: #f1f1f1;
  line-height: 80%;
}
.about-artist-header {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 60px;
  font-weight: bold;
  padding-left: 12px;
  padding-bottom: 20px !important;
  color: #1d1d1b;
  text-align: left;
  line-height: 80%;
}
.lan-header {
  font-family: 'BebasNeuePro', sans-serif ;
  font-size: 3em ;
  font-weight: bold ;
  color: #1d1d1b ;
  padding-top: 10%;
  line-height: 80% ;
  /* text-align: left ; */
  /* left: 0; */
  /* align-items: center; */
  /* position: fixed; */
}
.popup-header-en {
  font-family: 'BebasNeuePro', serif;
  font-size: 60px;
  font-weight: bold;
  color: #1d1d1b;
  text-align: left;
  line-height: 80%;
  padding: 8rem 0 2rem;
}
.popup-header-zh {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: #1d1d1b;
  text-align: left;
  line-height: 80%;
  padding: 8rem 0 2rem;
}
.about-artist-subheader {
  padding-left: 3px !important;
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 30px;
  font-weight: 400;
  /* font-weight: bold; */
  color: #1d1d1b;
  text-align: left;
  line-height: 100%;
}
.content-header-en {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 50px;
  /* letter-spacing: 8px; */
  font-weight: bold;
  padding-bottom: 30px !important;
  text-align: left;
  color: #1d1d1b;
  line-height: 80%;
  padding: 0rem 0 2rem;
}
.content-header-zh {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 50px;
  letter-spacing: 3px;
  font-weight: bold;
  padding-bottom: 30px !important;
  text-align: left;
  color: #1d1d1b;
  line-height: 80%;
  padding: 0rem 0 2rem;
}
.about-body {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 1em !important;
  text-align: left;
  color:#f1f1f1;
  padding: 0px 7px;
  /* padding-left: 12px !important; */
  /* padding-right: 12px !important; */
}
.about-artist-body {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 1em !important;
  text-align: left;
  color:#1d1d1b;
  padding: 0px 12px;
  margin-top: 32px !important;
}
.background-black {
  background-color: #1d1d1b;
  padding: 5rem 2rem 5rem 2rem!important;
}
.background-black-2 {
  padding: 5rem 20px 2rem 5vw!important;
}
.background-white {
  /* background-color: #f1f1f1; */
  padding: 0rem 2rem 5rem 2rem;
  /* --bs-gutter-x: 1.5rem !important; */
  /* width: 360px; */
  /* height: 100%; */
}
.quote {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #1d1d1b;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 80%;
  border-left: 2px solid #1d1d1b; /* Adjust the border color and width as needed */
  border-right: 2px solid #1d1d1b; /* Adjust the border color and width as needed */
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin:3rem 40px 3rem 40px;
}
.quote-lan {
  /* top: 51%; */
  height: 40vw;
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #1d1d1b;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 9vw;
  justify-content: center;
  border-left: 2px solid #1d1d1b; 
  border-right: 2px solid #1d1d1b; 
  margin: 2vh 80px;
  align-items: center;
}
.lan {
  border-color: transparent;
  background-color:#f1f1f1;
  text-decoration: none ;
  color: #1b1b1d;
  letter-spacing: 0.2em;
}
.lan:hover {
  /* transform-origin: center center!important; */
  transform: scale(1.3) !important;
  color: #72726a !important;
  transition: opacity 1s ease-in-out;
}
.icon-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.icon-flex-cur {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.dashboard-image {
  /* padding-top: 12rem; */
  padding-bottom: 35px;
  position: relative;
  scroll-snap-align: center;
  /* padding-bottom: 3rem; */
}
#snapbox {
  scroll-snap-type: x mandatory;
}
.snap {
  scroll-snap-align: center;
}
.content-image {
  align-items: center;
  padding: 3rem;
}
.schedule-time {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 20px;
  letter-spacing: 3px;
  /* font-weight: bold; */
  text-align: right;
}
.schedule-grey {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 1.5em !important;
  letter-spacing: 1px;
  text-align: right;
  color: #b0b1ac;
  /* display: flex; */
  /* justify-content: center; */
  /* line-height: 80%; */
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 0.2rem;
  text-transform: uppercase;
}
.schedule-small-title {
  font-family: 'BebasNeuePro', sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: right;
}
.box {
  max-width: 306px!important;
  max-height: 500px!important;
  width: 99%!important;
  height: 99%;
  background-image: url(./Assets/Test.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-left: 0!important;
  padding-right: 0!important;
}
.box-position {
  padding-top: 100px;
  padding-bottom: 100px;
  margin: auto;
  display: flex;
  align-items: flex-start;
}
.artist-img {/*useless*/
  max-width: 307px;
  max-height: 500px;
  width: 100%;
  height: 30%;
  background-image: url(./Assets/artist-img.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: -1px!important;
  margin-right: -1px!important;
}
.hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}
.dashboard-bg-box {
  /* position: absolute; */
  width: 340px;
  height: 400px;
  background-size: cover !important;
  background-image: url(./Assets/LR.png) !important;
  background-color: #2c8042;
  scroll-snap-align: center;
  /* transform: translateX(-50%); */
  /* top: 55%; */
  /* transform: translateY(-50%); */
  z-index: 0;
  border-radius: 2px;
}
.dashboard-box {
  width: 92vw;
  height: 57vh;
  max-width: 360px ;
  max-height: 400px ;
  background-size: cover !important ;
  background-image: url(./Assets/LR.png) !important ;
  scroll-snap-align: center ;
  z-index: 5 ;
  left: 50% ;
  border-radius: 2px ;
  margin: 0 auto;
}
.dashboard-box-colorbox {
  width: 100%;
  height: 100%;
  z-index: 5;
  /* background-image: url(./Assets/LR.png) !important ; */
}
.main-box {
  width: 92vw;
  height: 57vh;
  max-width: 360px;
  max-height: 400px;
  scroll-snap-align: center;
  z-index: 0;
  border-radius: 2px;
  top: 67px;
  left: 50%;
  position: absolute;
  transform: translate( -50% ,0 ) !important;
}
.canvas-3d {
  width: 300px;
  height: 300px;
}
.horizontal-list {
  /* list-style-type: none; */
  display: flex;
  overflow-x: scroll;
  margin: 0;
  scroll-snap-align: center;
}
.current-div {
  background-color: #f1f1f1;
  padding: 5px 10px 5px 10px;
  box-shadow: 0px 3px 3px #1d1d1b38;
}
.dblink_en {
  background-color: #1d1d1b;
  border-radius: 3px;
  padding: 10px 10px 10px 15px;
  box-shadow: 5px 5px 5px #1d1d1b38;
}
.dblink_zh {
  background-color: #1d1d1b;
  border-radius: 3px;
  padding: 10px 10px 10px 15px;
  box-shadow: 5px 5px 5px #1d1d1b38;
  font-family: 'BebasNeuePro', sans-serif;
  letter-spacing: 4px;
  font-weight: 350;
  z-index: 99;
}
.next,
.prev {
  color: #1d1d1b;
  top: calc(450px);
  position: fixed;
  /* background-image: linear-gradient(to left, rgb(29, 29, 27) 60%, rgba(241, 241, 241, 0)); */
  /* background: #1d1d1b; */
  /* border-radius: 50px 0px 0px 0px; */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 50px;
  text-shadow: 0px 0px 2px #1d1d1b41;
  z-index: 6;
  /* box-shadow: 0px 0px 10px #1d1d1b7c; */
}

.next {
  right: -4px;
  border-radius: 50px 50px 50px 50px !important;
  margin-top: -7px;
}

.prev {
  left: -4px;
  transform: scale(-1);
  border-radius: 50px 50px 50px 50px;
}
/* .next:hover {
  color: #f1f1f1;
  transition: all 0.3s ease 0s !important;
  transform: scale(1.1) translate(-5px,-2px);
  width: 45px;
  height: 45px;
}
.prev:hover {
  color: #f1f1f1;
  transition: all 0.3s ease 0s !important;
  transform: scale(-1.1) translate(-5px,-2px);
  width: 45px;
  height: 45px;
} */
.time {
  display: flex;
  justify-content: left;
  font-family:'BebasNeuePro', sans-serif;
  padding-left: 4%;
  align-items: center;
  letter-spacing: 2px;
}